import { useCallback, useEffect, useState } from 'react';
import { ThemeModeProvider, ThemeSwitchProvider } from './theme-context';
import { darkTheme, lightTheme } from './theme.css';

type ThemeMode = 'light' | 'dark';

const themeKey = 'sy-theme';

const detectThemeMode = (): ThemeMode => {
  if (import.meta.env.MODE === 'production') {
    return 'light';
  }

  if (typeof document === 'undefined') {
    return 'light';
  }

  try {
    const savedTheme = localStorage.getItem(themeKey);
    if (savedTheme === 'light' || savedTheme === 'dark') {
      return savedTheme;
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  } catch {
    return 'light';
  }
};

export const ThemeProvider = ({
  children,
  mode,
}: {
  children: React.ReactNode;
  mode?: ThemeMode;
}) => {
  const [theme, setTheme] = useState(() => mode || detectThemeMode());

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.remove(lightTheme);
      document.documentElement.classList.add(darkTheme);
      document.documentElement.style.setProperty('color-scheme', 'dark');
    } else {
      document.documentElement.classList.remove(darkTheme);
      document.documentElement.classList.add(lightTheme);
      document.documentElement.style.setProperty('color-scheme', 'light');
    }
  }, [theme]);

  const switchTheme = useCallback((theme: ThemeMode) => {
    setTheme(theme);

    try {
      localStorage.setItem(themeKey, theme);
    } catch {
      // ignore
    }
  }, []);

  return (
    <ThemeModeProvider value={theme}>
      <ThemeSwitchProvider value={switchTheme}>{children}</ThemeSwitchProvider>
    </ThemeModeProvider>
  );
};
